import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function App() {
  const [historicalData, setHistoricalData] = useState([]);
  const [dcaAmount, setDcaAmount] = useState(100);
  const [dcaFrequency, setDcaFrequency] = useState('weekly');
  const [simulationResult, setSimulationResult] = useState(null);

  useEffect(() => {
    fetchHistoricalData();
  }, []);

  const fetchHistoricalData = async () => {
    try {
      const response = await axios.get('https://api.coingecko.com/api/v3/coins/bitcoin/market_chart?vs_currency=usd&days=365');
      setHistoricalData(response.data.prices.map(([timestamp, price]) => ({ date: new Date(timestamp), price })));
    } catch (error) {
      console.error('Error fetching historical data:', error);
    }
  };

  const simulateDCA = () => {
    let totalInvested = 0;
    let totalBitcoin = 0;
    const intervals = { weekly: 7, monthly: 30, annually: 365 };
    const intervalDays = intervals[dcaFrequency];

    for (let i = 0; i < historicalData.length; i += intervalDays) {
      const price = historicalData[i].price;
      const bitcoinPurchased = dcaAmount / price;
      totalInvested += dcaAmount;
      totalBitcoin += bitcoinPurchased;
    }

    const currentValue = totalBitcoin * historicalData[historicalData.length - 1].price;
    const profit = currentValue - totalInvested;

    setSimulationResult({ totalInvested, totalBitcoin, currentValue, profit });
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
            <div>
              <h1 className="text-2xl font-semibold">Bitcoin DCA Simulator</h1>
            </div>
            <div className="divide-y divide-gray-200">
              <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                <div className="flex items-center space-x-4">
                  <label className="block">DCA Amount ($):</label>
                  <input
                    type="number"
                    value={dcaAmount}
                    onChange={(e) => setDcaAmount(Number(e.target.value))}
                    className="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  />
                </div>
                <div className="flex items-center space-x-4">
                  <label className="block">Frequency:</label>
                  <select
                    value={dcaFrequency}
                    onChange={(e) => setDcaFrequency(e.target.value)}
                    className="form-select mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  >
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="annually">Annually</option>
                  </select>
                </div>
                <button
                  onClick={simulateDCA}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Simulate DCA
                </button>
              </div>
              {simulationResult && (
                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  <p>Total Invested: ${simulationResult.totalInvested.toFixed(2)}</p>
                  <p>Total Bitcoin: {simulationResult.totalBitcoin.toFixed(8)} BTC</p>
                  <p>Current Value: ${simulationResult.currentValue.toFixed(2)}</p>
                  <p>Profit: ${simulationResult.profit.toFixed(2)}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {historicalData.length > 0 && (
        <div className="mt-8 mx-auto w-full max-w-4xl">
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={historicalData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="price" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
}